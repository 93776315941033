import React, { useEffect, useCallback, useState} from "react";
import Meta from "../../components/Meta";

import initUpbit, { closeUpbit } from '../../socket/upbit' //
import initBinance, { closeBinance } from '../../socket/binance'
import { useBearStore } from '../../zustand/store'
import HOME from "../../components/p2p/home";
import axios from "axios";
const live_version = true;
const admin_session = "02e99bd0ffafd31c6553296e2ac3b0efc925fd36eefe26a5085200c64359541902";

const P2P = () => {
  const upbitInit = useCallback(() => {
    closeUpbit()
    initUpbit()
  }, [])
  // 바이넨스 소켓 연결 초기화
  const binanceInit = useCallback(() => {
    closeBinance()
    initBinance()
  }, [])

  const { btc, market, setBTCSeller, setBTCBuyer } = useBearStore((state) => state)

  const [load, setLoad] = useState(false) // 렌더링(소켓 연결)
  const [KRWUSD, setKRWUSD] = useState(0)

  const getKRWCurrency =() => {
    const apiUrl = 'https://currency-fca2ikm5qq-uc.a.run.app/';
    return fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        const numberValue = parseFloat(data.rate.replace(/,/g, ''));
        const usd_krwp = numberValue *1;
        return usd_krwp;
      })
      .catch(error => {
        console.error('Error fetching KRW currency rate: ', error);
        return 1375;
      })
  }

  const getSellers_v2 = async () =>{
    const url = live_version? 'https://us-central1-kogods.cloudfunctions.net/get_member_v2':'http://127.0.0.1:5001/kogods/us-central1/get_member_v2'
    try {
      const response = await axios.get(url);
      const seller_tmp = [];
  
      for (const info of response.data.data) {
        console.log(info);
        const max_amount_krw_ = btc.krw * info.balance / 100000000;
        const seller_ = {
          uuid: info.toss_id,
          name: info.nickname,
          discount: info.fee_rate / 100 * -1,
          max_amount_krw: max_amount_krw_,
          twitter_id: '',
          katalk_id: info.katalk_url,
          session_id: info.session_id,
          session_register_cnt: 0
        };
        seller_tmp.push(seller_);
      }
      console.log('v2', seller_tmp);
      return seller_tmp;
    } catch (error) {
      console.log(error);
      return []
    }
  }

  const getSellers = async () => {
    const url = live_version? 'https://us-central1-kogods.cloudfunctions.net/p2p_seller':'http://127.0.0.1:5001/kogods/us-central1/p2p_seller'

    try {
      const res = await axios.get(url);
      const seller_tmp = [];
  
      for (const info of res.data.data) {
        let seller_ = info;
        if (seller_.session_id === admin_session) {
          try {
            const bal = await check_balance();
            console.log('bal', bal)
            const max_amount_krw_ = btc.krw * (bal / 1000);
            console.log('btc krw', btc.krw)
            if(max_amount_krw_ != 0)
              seller_.max_amount_krw = parseInt(max_amount_krw_ / 100000);
          } catch (error) {
            // Handle error if needed
          }
        }
        seller_tmp.push(seller_);
      }
      console.log('v1', seller_tmp);
      return seller_tmp;
    } catch (error) {
      console.log(error);
      return []

    }

  }

  const getBuyers = () => {
    const url = live_version? 'https://us-central1-kogods.cloudfunctions.net/p2p_buyer':'http://127.0.0.1:5001/kogods/us-central1/p2p_buyer'
    axios.get(url)
    .then((res) => {
      const buyer_temp =[]; // seller
      for (const info of res.data.data) {
        let buyer_ = info;
        buyer_temp.push(buyer_)
      }
      setBTCBuyer(buyer_temp)
    })
    .catch((error) => console.log(error));
  }

  const getSellers_all = async () =>{
    let sellersArr = [];
    const t1= await getSellers();
    sellersArr = sellersArr.concat(t1);

    const t2 = await getSellers_v2();
    sellersArr = sellersArr.concat(t2);
    console.log('sellersArr', sellersArr)
    setBTCSeller(sellersArr);

  }

  const updateUsers= () =>{
    console.log('update users')
    getBuyers();
    getSellers_all();

  }


  const check_balance = async () => {
    const response = await fetch("/api/lnbits/check_balance", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    });
    const data = await response.json();
    return data.data.balance/1000;
  }

  useEffect(() => {
    console.log('init')

    
    getKRWCurrency().then(krwusd=>{
      setKRWUSD(krwusd)
      binanceInit()
      upbitInit()
    })
    getBuyers();
    // getSellers_all();

  }, []);



  useEffect(() => {
    if (!load && btc.krw !== 0 && btc.usd !==0 && KRWUSD!==0)
    {
      setLoad(true) // 시세 변동 시 계산 => 코인 개수를 기준으로 가격 변환
      getSellers_all();
    }
  }, [btc, KRWUSD, load])

  return (
    <>
      <Meta title="비트코인 P2P 거래, OTC 거래" 
      desc={"비트코인, lightning network, otc 거래, p2p 거래"} keyword={"비트코인, p2p, lightning network, otc"}
      />
      {<HOME load={load} btc_krw={btc.krw} btc_usd={btc.usd} krw_usd={KRWUSD}  update_method={updateUsers}/>}
    </>
  );
};

export default P2P;

