import PriceShow from './price_show'
import BtcToPrice from './BtcToPrice'
import SellerList from './seller/SellerList'
import BuyerList from './buyer/BuyerList'
import { bidsModalShow, bidsModalHide, walletModalShow, walletModalhide } from "../../redux/counterSlice";
import { useDispatch } from "react-redux";
import BidsModalBuy from "../modal/registerModalBuy";
import BidsModalSell from "../modal/registerModalSell";
import React, { useState, useEffect } from "react";
import { useSession } from "next-auth/react";
import FaqAccordion from '../../components/faq/accoedion';

const live_version = true;

const Hero_8 = ({load, btc_krw, btc_usd, krw_usd, update_method}) => {
  const dispatch = useDispatch();
  const session = useSession();

  const btc_krw_upbit = load?btc_krw:0 // number
  const btc_usd_upbit = btc_krw_upbit/(load?krw_usd:1); //number

  const btc_krw_binance = Math.floor((load?btc_usd:30000)*krw_usd);
  
  const btc_krw_upbit_format = btc_krw_upbit.toLocaleString('en-US', { style: 'currency', currency: 'KRW' });
  const btc_usd_upbit_format = btc_usd_upbit.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  
  const btc_krw_binance_format = btc_krw_binance.toLocaleString('en-US', { style: 'currency', currency: 'KRW' });
  const btc_usd_binance_format = btc_usd.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

  const krw_format = krw_usd.toLocaleString('en-US', { style: 'currency', currency: 'KRW' });


  const premium = (100 * (btc_krw_upbit - btc_krw_binance) / btc_krw_upbit).toFixed(2);
  const diff = btc_krw_upbit - btc_krw_binance;
  const diff_format = diff.toLocaleString('en-US', { style: 'currency', currency: 'KRW' });

  const [curSessionId, setCurSessionId] = useState(live_version?'no_session':'test1');
  useEffect(() =>{
    if(session && session.data) {
      setCurSessionId(session.data.user.name)
    }
  },[session])


  return (
    <>
      {/* <!-- Hero --> */}
      <section className="hero relative py-20 md:pt-5">
      <BidsModalBuy update_method={update_method}></BidsModalBuy>
      <BidsModalSell update_method={update_method}></BidsModalSell>

      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden">
        <img src="/images/gradient.jpg" alt="gradient" />
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        <img src="/images/gradient_dark.jpg" alt="gradient dark" />
      </picture>

        <div className="container">
          <div className="mx-auto max-w-2xl pt-5 md:pt-20 text-center">
            <h1 className="mb-6 font-display text-5xl dark:text-white lg:text-6xl">
              비트코인 P2P
            </h1>
            <p className="mx-auto mb-10 max-w-m text-[1rem] lg:text-lg leading-normal dark:text-jacarta-50">
              라이트닝네트워크를 활용한 P2P, OTC 거래를 중개합니다.
            </p>
            
            {load&&<div className="md:mx-20 lg:mx-20 rounded-2.5xl bg-light-base py-4 shadow-sm dark:bg-jacarta-700" style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
              <PriceShow btc_krw_upbit_format={btc_krw_upbit_format} btc_usd_upbit_format={btc_usd_upbit_format} btc_krw_binance_format={btc_krw_binance_format} btc_usd_binance_format={btc_usd_binance_format} diff={diff_format} premium={premium}></PriceShow>
            </div>}



            {load&&<BtcToPrice></BtcToPrice>}
            
            {load&&<SellerList></SellerList>}
            {load&&<BuyerList></BuyerList>}
            {/* {
              <div className="md:mx-20 lg:mx-20 py-4 rounded-lg bg-white dark:bg-jacarta-700 dark:text-jacarta-300" style={{display:'flex', justifyContent:'center'}}>
              <button 
              className="text-sm rounded-full bg-[#0000ff33] py-3 px-8 text-center font-semibold transition-all hover:bg-accent-dark"
              onClick={() => {
                if(curSessionId == 'no_session') {
                  alert('라이트닝 월렛으로 로그인해주세요.')
                  return;
                }
                dispatch(walletModalShow())
              }}
              >
              {"비트코인 판매자로 등록하기"}
              </button>

              <button 
              className="text-sm rounded-full bg-[#FFD0D0] ml-2 py-3 px-8 text-center font-semibold transition-all hover:bg-accent-dark"
              onClick={() => {
                if(curSessionId == 'no_session') {
                  alert('라이트닝 월렛으로 로그인해주세요.')
                  return;
                }
                dispatch(bidsModalShow())
              }}
              >
              {"비트코인 구매자로 등록하기"}
              </button>              
              </div>
            } */}

            {load&&<div className="pt-10" style={{display:'flex', justifyContent:'center'}}>
            <div className="">
              <FaqAccordion />
            </div>
            </div>}


          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_8;
