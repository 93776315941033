import { comma } from '../../../utils/common'
import Button from '@mui/material/Button';
import Link from "next/link";
import { useRouter } from "next/router";


const Buyer = ({
  name,discount,katalk_id,max_amount_krw, session_register_cnt
}) => {
  const router = useRouter();

  const handleLinkToSeller = (name_) => {
    router.push(`/p2p/buy/${name_}`);
  }

  return (
    <div className="flex crypto-price items-center">
      <div className="crypto-price__coin flex w-[25%] items-center px-3 py-5">
        <div className="crypto-price__name flex-1 text-sm font-display font-semibold">
          <span className="text-jacarta-700 dark:text-white ml-3 mr-1">{name}</span>
          {/* {session_register_cnt&&<span className="text-jacarta-700 text-red">({session_register_cnt})</span>} */}
        </div>
      </div>
      <div className=" w-[30%] px-3 text-right text-jacarta-700 dark:text-white ">
        {comma(max_amount_krw.toFixed(4))} BTC
      </div>
      <div className=" w-[20%] px-1 text-right text-jacarta-700 dark:text-white ">
        { (discount*100).toFixed(2)}%
      </div>
      <div className=" w-[20%] pl-2 sm:pl-10 md:pl-10 lg:pl-10">
        <Button onClick={()=>handleLinkToSeller(katalk_id)}>판매</Button>
      </div>
    </div>
  );
};

export default Buyer;
