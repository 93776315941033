import { useState, useEffect } from "react";
import Buyer from "./Buyer";
import { useBearStore, BTCBuyer } from '../../../zustand/store'
function BuyerList({}) {
  useEffect(() => {
  }, []);

  const { btcBuyer } = useBearStore((state) => state)


  const filteredSellers = btcBuyer.sort((a, b) => {
    const b_cnt = b.session_register_cnt?b.session_register_cnt:0;
    const a_cnt = a.session_register_cnt?a.session_register_cnt:0;
    return (b_cnt - a_cnt);
  });  
  
  return (
    <div className="md:mx-20 lg:mx-20 py-4 rounded-lg bg-white dark:bg-jacarta-700 dark:text-jacarta-300">
      <div className="flex items-center dark:border-jacarta-600 border-jacarta-100 border text-sm" style={{backgroundColor:'#ff000033'}}>
        <div className="w-[25%] px-3 py-5">
          <div className="text-[0.7rem] sm:text-[0.875rem]">
            BTC 구매자
          </div>
          {/* <div className="text-[0.75rem] sm:text-[0.875rem]">
            (거래횟수)
          </div> */}
        </div>
        <div className="w-[30%] px-3 py-5 text-right lg:w-[30%] text-[0.75rem] sm:text-[0.875rem]">
          최대 구매금액
        </div>
        <div className="w-[20%] px-3 py-5 text-right lg:w-[20%]">
          할인율
        </div>
        <div className="w-[20%] py-5 text-right">바로가기</div>
      </div>

      {filteredSellers.map((buyer:BTCBuyer) => {
        return (
          <Buyer
            key={buyer.name}
            session_register_cnt={buyer.session_register_cnt}
            name={buyer.name}
            discount= {buyer.discount}
            katalk_id= {buyer.uuid}
            max_amount_krw= {buyer.max_amount_btc}
          />
        );
      })}
    </div>
  );
}
export default BuyerList;
