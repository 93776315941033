import { useState, useEffect } from "react";
import Seller from "./Seller";
import { useBearStore, BTCSeller } from '../../../zustand/store'
function SellerList({}) {
  const { btcSeller } = useBearStore((state) => state)
  const [filteredSellers, SetSellers] = useState([]);

  useEffect(() => {
    const filteredSellers_ = btcSeller.sort((a, b) => {
      const b_cnt = b.session_register_cnt?b.session_register_cnt:0;
      const a_cnt = a.session_register_cnt?a.session_register_cnt:0;
      return (b_cnt - a_cnt);
    });
    SetSellers(filteredSellers_)
  }, [btcSeller]);
  
  return (
    <div className="md:mx-20 lg:mx-20 py-4 rounded-lg bg-white dark:bg-jacarta-700 dark:text-jacarta-300">
      <div className="flex items-center dark:border-jacarta-600 border-jacarta-100 border text-sm" style={{backgroundColor:'#0000ff33'}}>
        <div className="w-[25%] px-3 py-5">
          <div className="text-[0.7rem] sm:text-[0.875rem]">
            BTC 판매자
          </div>
          {/* <div className="text-[0.75rem] sm:text-[0.875rem]">
            (거래횟수)
          </div> */}
        </div>
        <div className="w-[30%] px-3 py-5 text-right lg:w-[30%] text-[0.75rem] sm:text-[0.875rem]">
          최대 판매금액
        </div>
        <div className="w-[20%] px-3 py-5 text-right lg:w-[20%]">
          수수료
        </div>
        <div className="w-[20%] py-5 text-right">바로가기</div>
      </div>

      {filteredSellers.map((seller:BTCSeller) => {
        return (
          <Seller
            key={seller.name}
            session_register_cnt={seller.session_register_cnt}
            name={seller.name}
            discount= {seller.discount}
            katalk_id= {seller.uuid}
            max_amount_krw= {seller.max_amount_krw}
          />
        );
      })}
    </div>
  );
}
export default SellerList;
