import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { walletModalhide } from "../../redux/counterSlice";
import { QRCode } from 'react-qrcode-logo';
import ReactLoading from 'react-loading';
import { useSession } from "next-auth/react";
import { useBearStore, UserInfo } from '../../zustand/store'

var exist_ = false;


const live_version = true;
const BidsModal = ({update_method}) => {
  const walletModal = useSelector((state) => state.counter.walletModal);

  const dispatch = useDispatch();
  const session = useSession();

  const [nickname, setNickName] = useState("");
  const [discount, setDiscount] = useState("");
  const [btcCnt, setBTCCnt] = useState("");
  const [katalkId, setKatalkId] = useState("");
  const [twitterId, setTwitterId] = useState("");
  
  const [memo_, setMemo] = useState("");
  const [loading_, setLoading] = useState(false);
  const [invoice, setInvoice] = useState("");
  const [copyMsg, setCopyMsg] = useState("invoice 복사하기");
  const [payStatus, setPayStatus] = useState(false); // 사용자가 입금했는지 여부
  const [hash, setHash] = useState(""); // invoice 상태 체크하기 위한 hash
  const [curSessionId, setCurSessionId] = useState(live_version?'no_session':'test1');
  const { userInfo, setUserInfo } = useBearStore((state) => state)


  useEffect(() =>{
    if(session && session.data) {
      setCurSessionId(session.data.user.name)
    }
  },[session])

  useEffect( () =>{
    if(userInfo.length>0){
      setNickName(userInfo[0].name)
      setKatalkId(userInfo[0].katalk_id)
      setTwitterId(userInfo[0].twitter_id)
    }
  }, userInfo)




  const registerBuyer = async (data)=> {
    // success
    const url = live_version? 'https://us-central1-kogods.cloudfunctions.net/set_p2p_seller':'http://127.0.0.1:5001/kogods/us-central1/set_p2p_seller'

    const validKatalkId=  katalkId.split("https://open.kakao.com/o/")[1];
    const requestBody = {
      name:nickname, discount:discount*-1, max_amount_krw:btcCnt*1, twitter_id:twitterId, katalk_id:validKatalkId,
      session_id:curSessionId
    }
    try {
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type'
        }
      })
    } catch (error) {
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pollApi = async () => {
    try {
      const response = await fetch(`/api/lnbits/check_status?hash=${hash}`);
      const data = await response.json();
      console.log('poll', data);
      if(data.result){
        if(data.result == true && data.status){
          // call check paid status api
          if(!exist_) {
            exist_ = true;
            console.log('query check state', data.status);
            setPayStatus(data.status);
            initState();
            dispatch(walletModalhide())
            registerBuyer().then(a=>{
              update_method();
            })
        }
        }
        // data.status가 true이면.. 성공이라고 띄우기
      }
    } catch (error) {
      console.log('error',error)
    }
  };

  const button_callback = async () => {

    if(curSessionId =="no_session") {
      alert('라이트닝 월렛으로 로그인해주세요.')
      return;
    }
    // check valid
    exist_ = false;
    console.log('format', nickname, discount, btcCnt, katalkId, twitterId)
    const origin_katalk = katalkId;
    setDiscount(discount*0.01)
    try {
      const validKatalkId=  katalkId.split("https://open.kakao.com/o/")[1]
      if(validKatalkId==undefined)
        throw 'error';
        setKatalkId(validKatalkId);
    } catch (error) {
      alert('카톡 오픈채팅방의 링크를 입력해주세요. ex) https://open.kakao.com/o/1234')
      setKatalkId("")
      return
    }

    setUserInfo([{
      name:nickname,
      katalk_id:origin_katalk,
      twitter_id:twitterId
    } ])
    setLoading(true);

    query_create_invoice({
      amount:100,
      from:'donate_register',
      message:'register',
      number:1
    }).then(a=>{
      if(a.result) {
        setInvoice(a.invoice)
        setHash(a.hash)
        setLoading(false);
      }
      else {
        setLoading(false);
      }
    })
  }

  const query_create_invoice = async (data) =>{
    // const url = 'http://127.0.0.1:5001/kogods/us-central1/create_invoice_info'
    const url = live_version? 'https://us-central1-kogods.cloudfunctions.net/create_invoice_info':'http://127.0.0.1:5001/kogods/us-central1/create_invoice_info'
    const requestBody = {
      "amount":data.amount,
      "from":data.from,
      "msg":data.message,
      "number":data.number
    }
    try {
      var response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type'
        }
      })
      return response.json();
    } catch (error) {
      return {result:false};      
    }
  }


  useEffect(()=>{
    console.log('useEffect', payStatus, invoice)

  },[])
  // invoice check
  useEffect(() => {
    if (invoice !== '' && payStatus == false) {
      console.log('useEffect', payStatus, invoice)
      const interval = setInterval(pollApi, 1000); // poll every 5 seconds
      return () => clearInterval(interval);
    }
  }, [payStatus, invoice]);




  async function copyTextToClipboard(text) {
    // setOpen(true);
    setCopyMsg("복사 됐습니다.")
    setTimeout(()=>{
      setCopyMsg("invoice 복사하기")
    },1000);
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }


  const initState = () =>{
    setMemo("");
    setInvoice("");
    setLoading(false);
    setPayStatus(false);

    // setKatalkId("");
    // setTwitterId("");
    setBTCCnt("");
    // setNickName("");
    setDiscount("");

  }
  const handleMemoChanged = (e) => {
    e.preventDefault();
    setMemo(e.target.value);
  };


  const QRview = () =>{
    return(
      <div>
      <div className={walletModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                판매자 등록하기
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  initState();
                  dispatch(walletModalhide())}}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>
            <div>
            {
              loading_?
              
              <div style={{ width: '300px', height: '300px', display:'flex', justifyContent:'center', alignItems:"center"}}>
                <ReactLoading type={"spokes"} color="0xff0000" width={100} height={100}/>
              </div>
              :
              <div>
              <a onClick={()=>{copyTextToClipboard(invoice)}}>
              <h3 className="font-display text-jacarta-700 py-2 text-center text-1xl font-medium dark:text-white">
              {copyMsg}
              </h3>            
              </a>
              <a onClick={()=>{copyTextToClipboard(invoice)}}>
                <QRCode
                ecLevel="H"
                value={"lightning:"+invoice}
                size={300}
                logoImage="images/trading/bitcoin.png"
                logoWidth={50}
                />
              </a>
              </div>

            }

            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
  if(invoice == "" && loading_ == false){
    return (
      <div>
        <div className={walletModal ? "modal fade show block" : "modal fade"}>
          <div className="modal-dialog max-w-2xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="placeBidLabel">
                  등록하기
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    initState();
                    dispatch(walletModalhide())}}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                  </svg>
                </button>
              </div>
  
              {/* <!-- Body --> */}
              <div className="modal-body p-6">

                  <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                      
      
                  <input
                    type="text"
                    className="text-sm focus:ring-accent h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
                    placeholder="닉네임"
                    value={nickname}
                    onChange={(e) => setNickName(e.target.value)}
                  />
                </div>              
                <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                  
  
                  <input
                    style={{textTransform:'lowercase'}}
                    type="text"
                    className="focus:ring-accent text-sm h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
                    placeholder="카카오 오픈채팅 (https://open.kakao.com/o/123)"
                    value={katalkId}
                    onChange={(e) => {
                      try {
                        setKatalkId(e.target.value)
                      } catch (error) {
                      }
                    }}
                  />
                </div>

                <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                  
  
                <input
                  style={{textTransform:'lowercase'}}
                  type="text"
                  className="focus:ring-accent text-sm h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
                  placeholder="트위터 아이디 ex) yanabu_"
                  value={twitterId}
                  onChange={(e) => setTwitterId(e.target.value)}
                />
              </div>

              <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                  
  
              <input
                type="number"
                className="focus:ring-accent text-sm h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
                placeholder="수수료 %"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </div>

            <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                  
  
            <input
              type="number"
              className="focus:ring-accent text-sm h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
              placeholder="최대 비트코인 판매 금액 (원)"
              value={btcCnt}
              onChange={(e) => setBTCCnt(e.target.value)}
            />
          </div>

                {/* <!-- Terms --> */}
                <div className="mt-4 flex flex-col items-center">
                 
                  <label
                    htmlFor="terms"
                    className="dark:text-jacarta-200 text-sm"
                  >
                    위의 양식을 채우고 100sats를 전송해주세요.
                  </label>

                  <label
                    htmlFor="terms"
                    className="dark:text-jacarta-200 text-sm"
                  >
                    결제가 완료되면 판매자로 등록됩니다.
                  </label>
                </div>
              </div>
              {/* <!-- end body --> */}
              <div className="modal-footer">
                <div className="flex items-center justify-center space-x-4">
                  <button
                    type="button"
                    className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                    onClick={()=>{
                      button_callback()
                    }}
                  >
                    100 sats 보내기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else {
    return QRview();
  }

};

export default BidsModal;
