


import React, { useEffect, useState } from "react";
import Image from "next/image";



const PremiumElement = ({premium, diff})=>{
  return (
      <div className="text-center">
        <div className="text-right upbit">
            <span>Premium</span>
        </div>
        <div className="text-right">
          <div className="sectionKrw text-[1.0rem] lg:text-[1.3rem]"><span>{premium}%</span></div> 
          <div className="text-right text-[0.8rem] lg:text-[0.9rem]" >{diff}원</div>
        </div>
      </div>
  );
}



const PriceElement = ({btc_krw, btc_usd, img_path, exchange_name})=>{
  return (
      <div className="text-center">
        <div className="text-left upbit">
          <Image
            src={img_path}
            alt="upbit logo"
            className="align-middle"
            loading="lazy"
            height={25}
            width={25}
          />
          <div className="inline-block">
            <span>{exchange_name}</span>
          </div>
        </div>
        <div className="text-left">
          <div className="sectionKrw text-[0.9rem] lg:text-[1.3rem]" ><span>{btc_krw}원</span></div> 
          <div className="text-right text-[0.8rem] lg:text-[0.9rem]">{btc_usd}</div>
        </div>
      </div>
  );
}

const PriceShow = ({btc_krw_upbit_format, btc_usd_upbit_format, btc_krw_binance_format, btc_usd_binance_format, diff, premium
}) => {
  return (
    <div className="" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <PriceElement btc_krw={btc_krw_upbit_format} btc_usd={btc_usd_upbit_format} exchange_name={"Upbit"} img_path={"/images/p2p/logo_upbit.svg"}></PriceElement>

      <div className="ml-2 mr-2 lg:ml-3 lg:mr-3 text-[1.5rem]">-</div>
      
      <PriceElement btc_krw={btc_krw_binance_format} btc_usd={btc_usd_binance_format} exchange_name={"Binance"} img_path={"/images/p2p/logo_binance.svg"}></PriceElement>

      <div className="ml-2 mr-2 lg:ml-3 lg:mr-3 text-[1.5rem]">=</div>
      <PremiumElement premium={premium} diff={diff}></PremiumElement>


    </div>

  );
};

export default PriceShow;
